<template>
  <v-row class="groups">
    <v-col cols="12" class="pa-0">
      <div class="title justify-space-between align-baseline">
        <div class="white--text text-uppercase text-sm-h5 text-h6 font-weight-bold">groups</div>
        <div class="d-flex title-input">
          <v-text-field
            v-model="search"
            placeholder="Search by name"
            prepend-inner-icon="mdi-magnify"
            :append-icon="search ? 'mdi-close' : ''"
            @click:append="clearSearch"
            outlined
            dense
            required
            color="input"
            @change="(page = 1), getData()"
          >
          </v-text-field>
          <v-btn @click="newGroup = !newGroup" class="white--text ml-5" width="184" height="48" color="secondary">Create new</v-btn>
        </div>
      </div>
      <v-card
        v-if="load"
        tile
        elevation="5"
        max-width="1070"
        height="70vh"
        min-height="250"
        class="mx-lg-auto mx-4 mx-sm-15 pa-3 d-flex align-center justify-center flex-column"
      >
        <v-img max-height="80" contain src="@/assets/icon/loader.gif"></v-img>
        <div class="text-body-2 opasity--text">Loading...</div>
      </v-card>
      <v-card v-else elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-sm-15">
        <v-card flat height="70vh" min-height="260" v-if="nothing" class="pa-3 d-flex align-center justify-center flex-column">
          <v-img max-height="124" contain src="@/assets/img/notFound.svg"></v-img>
          <div class="text-center text-h5 font-weight-bold mt-8">Looks like there are not groups according to your request</div>
        </v-card>
        <v-card
          flat
          height="70vh"
          min-height="260"
          v-else-if="block || !data.result.length"
          class="pa-3 d-flex align-center justify-center flex-column"
        >
          <v-img max-height="124" contain src="@/assets/img/notFound.svg"></v-img>
          <div class="text-center text-h6 font-weight-bold mt-8">Looks like there are no groups for your account.</div>
          <!-- <div class="text-center opasity--text font-weight-regular mt-2">Please, contact support to resolve this issue.</div> -->
          <div class="text-center mt-5">
            <v-btn color="primary" height="48" width="184" elevation="0" @click="newGroup = !newGroup">Create new</v-btn>
          </div>
        </v-card>
        <div v-else>
          <v-data-table
            hide-default-footer
            fixed-header
            :headers="headers"
            :items="data.result"
            item-key="name"
            :items-per-page="Number(itemsPerPage)"
            :height="`calc(100vh - 230px)`"
            class="p-table"
          >
            <template v-slot:top>
              <v-dialog v-model="deleteGroup" max-width="536">
                <v-card class="pa-md-10 px-sm-5 py-sm-10 py-5 px-4 text-center">
                  <div class="text-h5 font-weight-bold">Delete Group</div>
                  <div class="mt-3 opasity--text">Do you really want to delete {{ editedItem.name }}?</div>
                  <div class="mt-7">
                    <v-btn width="120" height="48" class="mr-4 white--text" color="gray" @click="closeDialog">no</v-btn>
                    <v-btn width="120" height="48" color="secondary" @click="deleteItem">yes</v-btn>
                  </div>
                </v-card>
              </v-dialog>
            </template>
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="item in items" :key="item.id" height="52" class="link" @click="openContact(item.id)">
                  <td>{{ item.name }}</td>
                  <td class="d-sm-table-cell d-none clip">{{ item.description }}</td>
                  <td>{{ item.contacts }}</td>
                  <td class="text-right">
                    <v-btn class="rounded" text icon tile elevation="0" @click.stop="openItem(item)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <span class="divider-vertical"></span>
                    <v-btn class="rounded" color="error" text icon tile elevation="0" @click.stop="openDelete(item)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
            <template v-slot:footer>
              <v-divider></v-divider>
              <v-row class="mt-2 pb-2 px-10" align="center" justify="end">
                <span class="grey--text">Rows per page:</span>
                <div class="perPageSelect">
                  <v-select
                    v-model="itemsPerPage"
                    :items="itemsPerPageArray"
                    menu-props="auto"
                    hide-details
                    single-line
                    dense
                    @change="getData"
                  ></v-select>
                </div>
                <v-btn icon color="secondary" class="mr-1" @click="formerPage">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <span class="mx-4 grey--text">{{ page }} of {{ data.pages }}</span>
                <v-btn icon color="secondary" class="ml-1" @click="nextPage">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-row>
            </template>
          </v-data-table>
        </div>
      </v-card>
      <v-dialog v-model="newGroup" max-width="536">
        <v-card class="pa-md-10 px-sm-5 py-sm-10 py-5 px-4">
          <div class="text-h5 font-weight-bold mb-6">Add new group</div>
          <v-text-field
            v-model="general.name"
            :error-messages="nameErrors"
            label="Group Name"
            filled
            outlined
            dense
            required
            color="input"
          ></v-text-field>
          <v-text-field
            v-model="general.description"
            :error-messages="descriptionErrors"
            label="Description"
            filled
            outlined
            dense
            color="input"
          ></v-text-field>
          <div class="mt-7 d-flex flex-column align-center">
            <v-btn elevation="0" width="184" height="48" color="primary" @click="createItem">Create</v-btn>
            <v-btn width="184" height="48" text color="secondary" class="mt-3" @click="newGroup = !newGroup">Close</v-btn>
          </div>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  validations: {
    general: {
      name: { required },
    },
  },
  data() {
    return {
      general: {
        name: '',
        description: '',
      },
      search: this.$route.query.src || '',
      itemsPerPage: Number(this.$route.query.page_size) || 10,
      page: Number(this.$route.query.page) || 1,
      editedIndex: -1,
      editedItem: {},
      load: true,
      block: true,
      nothing: false,
      newGroup: false,
      deleteGroup: false,
      headers: [
        { text: 'Name', value: 'name', sortable: false },
        { text: 'Description', value: 'description', class: 'd-sm-table-cell d-none', sortable: false },
        { text: 'Contacts', value: 'contacts', sortable: false },
        { text: 'Actions', align: 'end', value: 'actions', sortable: false },
      ],
      statusError: null,
    };
  },
  mounted() {
    this.getData();
    this.$store.dispatch('getPagination');
  },
  methods: {
    notifi(btn) {
      if (btn == 'create') {
        this.$notify({ message: `Group ${this.general.name} create`, horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'update') {
        this.$notify({ message: `Group ${this.name} updete`, horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'delete') {
        this.$notify({ message: 'Group delete', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'search400') {
        this.$notify({
          message: 'This field must be at least 3 characters, maximum 10',
          type: 'warning',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == '500') {
        this.$notify({
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    clearSearch() {
      this.page = 1;
      this.search = '';
      this.getData();
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
      this.getData();
    },
    nextPage() {
      if (this.page != this.data.pages) {
        this.page += 1;
        this.getData();
      }
    },
    formerPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
        this.getData();
      }
    },
    async createItem() {
      this.error = null;
      this.$v.general.$touch();
      if (!this.$v.general.$invalid) {
        const data = new Object();
        data.name = this.general.name;
        data.description = this.general.description;
        await this.$store
          .dispatch('createGroups', data)
          .then((res) => {
            this.notifi('create');
            this.$router.push({ name: 'Group', params: { groupId: res.data.id } });
          })
          .catch((e) => {
            this.error = e.response.data.error[0];
          });
      }
    },
    openContact(id) {
      this.$router.push(`/groups/${id}/contact`);
    },
    openItem(item) {
      this.$router.push({ name: 'Group', params: { groupId: item.id } });
    },
    openDelete(item) {
      this.editedIndex = this.data.result.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.deleteGroup = true;
    },
    closeDialog() {
      this.newGroup = false;
      this.deleteGroup = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, {});
        this.editedIndex = -1;
      });
    },
    async updateItem() {
      const data = new Object();
      data.name = this.editedItem.name;
      data.description = this.editedItem.description;
      const id = this.editedItem.id;
      await this.$store.dispatch('updateGroups', { data, id }).then(() => {
        this.getData();
        this.notifi('update');
        this.closeDialog();
      });
    },
    async deleteItem() {
      await this.$store.dispatch('deleteGroups', this.editedItem.id).then(() => {
        this.getData();
        this.notifi('delete');
        this.closeDialog();
      });
    },
    async getData() {
      await this.$store
        .dispatch('getGroupsList', {
          pageSize: this.itemsPerPage,
          search: this.search,
          page: this.page,
        })
        .then(() => {
          this.nothing = false;
          this.block = false;
        })
        .catch((e) => {
          this.getDataError(e);
        })
        .finally(() => {
          this.load = false;
        });
    },
    getDataError(e) {
      this.statusError = e.response.status;
      if (e.response.data.error[0] === 'page__out_of_bounds') {
        this.page = 1;
        this.getData();
      } else if (e.response.data.error[0] === 'account__not_found') {
        this.$store.dispatch('setGroupsList', {});
        this.block = true;
      } else if (e.response.data.error[0] === 'result__not_found') {
        this.$store.dispatch('setGroupsList', {});
        this.nothing = true;
      } else if (this.statusError >= 400 && this.statusError < 500) {
        this.notifi('search400');
      } else if (this.statusError >= 500) {
        this.notifi('500');
      }
    },
  },
  watch: {
    newGroup(val) {
      val || this.closeDialog();
    },
    deleteGroup(val) {
      val || this.closeDialog();
    },
  },
  computed: {
    data() {
      return this.$store.getters.groupsList;
    },
    itemsPerPageArray() {
      return this.$store.getters.pagination;
    },
    importProgress() {
      return this.$store.getters.importProgress;
    },
    imporId() {
      return this.$store.getters.id;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.general.name.$dirty) {
        return errors;
      }
      !this.$v.general.name.required && errors.push('Please enter group name');
      this.error == 'name__invalid' && errors.push('Provided group name is not valid');
      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.general.name.$dirty) {
        return errors;
      }
      this.error == 'description__invalid' && errors.push('Provided description is not valid');
      return errors;
    },
  },
  destroyed() {
    this.$store.dispatch('setGroupsList', {});
    this.$store.dispatch('setImportProgContants', {});
  },
};
</script>

<style lang="scss">
.groups {
  .clip {
    max-width: 250px;
  }
}
</style>
